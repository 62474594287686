$(document).ready(function() {
  $('[data-toggle="tooltip"]').tooltip();

  $("#slideProductsBtn").tooltip();

  // home carousel featured product info btn tooltip
  // $("#slideProductsBtn").tooltip({
  //   trigger: "manual"
  // });
  // $("#slideProductsBtn").tooltip("show");
  // $("#slideProductsBtn").on("click", function() {
  //   $(this).tooltip("toggle");
  // });
});
