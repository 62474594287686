$(document).ready(() => {
  // scroll
  $(window).on("scroll", function() {
    if ($(this).scrollTop() > 50) {
      if (!$(".navbar").hasClass("navbar--scrolled")) {
        $(".navbar").addClass("navbar--scrolled");
      }
    } else {
      if ($(".navbar").hasClass("navbar--scrolled")) {
        $(".navbar").removeClass("navbar--scrolled");
      }
    }
  });

  // menu
  const menuOpen = () => {
    $("body").css("overflow", "hidden");
    $("#menu").addClass("menu--opened");
    $(".navbar").addClass("navbar--scrolled");
    setTimeout(function() {
      $(".menu__wrapper").fadeIn(350);
    }, 500);
  };

  const menuClose = () => {
    $(".menu__wrapper").fadeOut(350);
    setTimeout(function() {
      $("#menu").removeClass("menu--opened");
      $("body").css("overflow", "auto");
      if ($(window).scrollTop() < 50) {
        $(".navbar").removeClass("navbar--scrolled");
      }
    }, 350);
  };

  $(".hamburger").on("click", function() {
    $(this).toggleClass("hamburger--clicked");
    if ($(this).hasClass("hamburger--clicked")) {
      menuOpen();
    } else {
      menuClose();
    }
  });

  $(window).on("resize", () => {
    if (
      $(window).width() > 991 &&
      $(".hamburger").hasClass("hamburger--clicked")
    ) {
      $(".hamburger").click();
    }
  });
});
