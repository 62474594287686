// Font Awesome
// Font icon set

import { library, dom } from "@fortawesome/fontawesome-svg-core";
import {
  faSearch,
  faPlayCircle,
  faTimes,
  faAngleRight,
  faPhoneAlt,
  faAngleLeft,
  faArrowUp,
  faMapMarkerAlt,
  faEnvelope,
  faPrint,
  faPlus,
  faMinus,
  faAngleDown,
  faLongArrowAltRight,
  faCalendarAlt,
  faClock,
  faInfo,
  faUser,
  faBars,
  faAt,
  faHeart,
  faDownload,
  faTags,
  faStar
} from "@fortawesome/free-solid-svg-icons";
import {
  faFacebookF,
  faPinterestP,
  faInstagram,
  faLinkedinIn,
  faYoutube,

} from "@fortawesome/free-brands-svg-icons";

$(document).ready(() => {
  library.add(
    faSearch,
    faPlayCircle,
    faTimes,
    faAngleRight,
    faPhoneAlt,
    faAngleLeft,
    faArrowUp,
    faMapMarkerAlt,
    faEnvelope,
    faFacebookF,
    faPinterestP,
    faInstagram,
    faLinkedinIn,
    faYoutube,
    faPrint,
    faPlus,
    faMinus,
    faAngleDown,
    faLongArrowAltRight,
    faCalendarAlt,
    faClock,
    faInfo,
    faUser,
    faBars,
    faAt,
    faHeart,
    faDownload,
    faTags,
    faStar
  );

  dom.watch();
});
